<template>
    <div>
        <b-form-group v-if="canAuthenticate">
            <div class="btn-group" v-if="isAuthenticated">
                <button class="btn btn-primary" disabled><i class="fa fa-link"></i> Gekoppeld</button>
                <button @click="deauthenticate()" class="btn btn-outline-primary"><i class="fa fa-unlink"></i> Ontkoppelen</button>
            </div>
            <div class="btn-group" v-else>
                <button @click="authenticate()" class="btn btn-outline-primary"><i class="fa fa-unlink"></i> Koppelen</button>
                <button class="btn btn-primary" disabled><i class="fa fa-link"></i> Ontkoppeld</button>
            </div>
            <div v-if="!isAuthenticated">
                <small>Klik op Koppelen om te factureren naar Teamleader.</small>
            </div>
        </b-form-group>
        <div class="card border-primary">
            <div class="card-header bg-primary border-primary" style="cursor: pointer" @click="expanded_settings = !expanded_settings"><i class="fa fa-cog"></i>Teamleader API Instellingen
            </div>
            <div class="card-body" v-if="expanded_settings">
                <div>
                    Volg deze stappen om Teamleader te koppelen:
                    <ol>
                        <li>Ga naar <a href="https://marketplace.teamleader.eu/nl/nl/ontwikkel" target="_blank">Teamleader
                            Marketplace.</a></li>
                        <li><b>Login</b> met je gebruikersnaam en wachtwoord.</li>
                        <li>Klik op <b>Bouw</b> -> <b>Bouw je integraties</b>.</li>
                        <li>Maak een nieuwe integratie aan.</li>
                        <li>Volg de stappen op Teamleader.</li>
                        <li>Vul de benodige data hieronder in.</li>
                    </ol>
                </div>

                <form @submit.prevent="handleTeamleaderApiConfig">
                    <div class="row">
                        <div class="col-4">
                            <b-form-group label="Client ID">
                                <b-form-input v-model="form.client_id" required></b-form-input>
                            </b-form-group>
                        </div>
                        <div class="col-4">
                            <b-form-group label="Client Secret">
                                <b-input-group>
                                    <b-form-input :type="client_secret_show ? 'text' : 'password'"
                                                  v-model="form.client_secret"
                                                  ref="client_secret" required></b-form-input>
                                    <b-input-group-text slot="append" @click="client_secret_show = !client_secret_show"
                                                        style="cursor: pointer"><i class="fa fa-eye"></i>
                                    </b-input-group-text>
                                </b-input-group>
                            </b-form-group>
                        </div>
                    </div>
                    <button class="btn btn-sm btn-primary" type="submit">Opslaan</button>
                </form>
            </div>
        </div>

        <div class="card border-primary" v-if="isAuthenticated">
            <div class="card-header bg-primary border-primary"><i class="fa fa-cog"></i> Koppelingsinstellingen</div>
            <div class="card-body">
                <form @submit.prevent="handleExtraConfig">
                    <b-form-group label="Standaard administratie">
                        <b-input-group>
                            <b-input-group-text v-if="loading_teamleader_data" slot="prepend" class="text-primary"><i class="fa fa-circle-o-notch fa-spin"></i>&nbsp;Administratie laden...</b-input-group-text>
                            <b-form-select v-model="form.default_department" required>
                                <option :value="undefined" disabled>[ Kies administratie ]</option>
                                <option v-for="item in teamleader_departments" :value="item.id">
                                    {{ item.name }}
                                </option>
                            </b-form-select>
                        </b-input-group>
                    </b-form-group>
                    <b-form-group label="Standaard product">
                        <b-input-group>
                            <b-input-group-text v-if="loading_teamleader_data" slot="prepend" class="text-primary"><i class="fa fa-circle-o-notch fa-spin"></i>&nbsp;Producten laden...</b-input-group-text>
                            <b-form-select v-model="form.default_product">
                                <option :value="undefined">[ Kies een product ]</option>
                                <option v-for="item in teamleader_products" :value="item.id">
                                    {{ item.name }}
                                </option>
                            </b-form-select>
                        </b-input-group>
                        <small>(optioneel) Het standaard product zal gebruikt worden om factuurregels te koppelen aan het geselecteerde product.</small>
                    </b-form-group>
                    <b-form-group label="Standaard grootboekrekening">
                        <b-input-group>
                            <b-input-group-text v-if="loading_teamleader_data" slot="prepend" class="text-primary"><i class="fa fa-circle-o-notch fa-spin"></i>&nbsp;Grootboekrekeningen laden...</b-input-group-text>
                            <b-form-select v-model="form.default_bookkeeping_account">
                                <option :value="undefined">[ Kies een grootboekrekening ]</option>
                                <option v-for="item in teamleader_bookkeeping_accounts" :value="item.id">
                                    {{ item.name }}
                                </option>
                            </b-form-select>
                        </b-input-group>
                        <small>(optioneel) De standaardgrootboekrekening zal gebruikt worden om factuurregels te koppelen aan de juiste grootboekrekening.</small>
                    </b-form-group>
                    <b-form-group label="Standaard tarief" required>
                        <b-input-group prepend="€">
                            <b-form-input v-model="form.default_rate" @keypress.native="onlyNumber"></b-form-input>
                        </b-input-group>
                        <small>Dit tarief wordt standaard ingevuld bij facturatie. Dit is aanpasbaar per klant in TOPdesk bij de vrije velden, maar kan ook per factuur aangepast worden.</small>
                    </b-form-group>
                    <loading-button type="submit" :loading="loading_form" class="btn btn-sm btn-primary">Opslaan</loading-button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import mix from './mixin';

    export default {
        mounted() {
            this.getApiConfig('teamleader')
                .then(() => {
                    if(this.isAuthenticated) {
                        this.getTeamleaderDepartments();
                        this.getTeamleaderProducts();
                        this.getTeamleaderBookkeepingAccounts();
                    } else {
                        this.expanded_settings = true;
                    }
                })
        },
        mixins: [mix],
        data() {
            return {
                form: {
                    client_id: undefined,
                    client_secret: undefined,
                    default_department: undefined,
                    default_bookkeeping_account: undefined,
                    default_rate: undefined,
                },
                expanded_settings: false,
                client_secret_show: false,
                loading_teamleader_data: false,
                teamleader_departments: [],
                teamleader_products: [],
                teamleader_bookkeeping_accounts: []
            }
        },
        computed: {
            isAuthenticated() {
                return !!this.api_config.session;
            },
            canAuthenticate() {
                if(this.api_config && this.api_config.config) {
                    return this.api_config.config.client_id && this.api_config.config.client_secret;
                }
                return false;
            }
        },
        watch: {
            isAuthenticated(val) {
                this.$emit('isAuthenticated', val);
            }
        },
        methods: {
            handleTeamleaderApiConfig() {
                this.updateApiConfig('teamleader').then(() => {
                    this.$toastr.s('Teamleader instellingen opgeslagen!');
                }).then(() => {
                    return this.getApiConfig('teamleader');
                });
            },
            handleExtraConfig() {
                return this.updateApiConfig('teamleader').then(() => {
                    this.$toastr.s('Teamleader instellingen bijgewerkt!');
                })
            },
            getTeamleaderDepartments() {
                this.loading_teamleader_data = true;
                return this.$http.get('teamleader/departments').then((response) => {
                    this.teamleader_departments = response.data;
                    this.loading_teamleader_data = false;
                }).catch(() => {
                    this.loading_teamleader_data = false;
                })
            },
            getTeamleaderProducts() {
                this.loading_teamleader_data = true;
                return this.$http.get('teamleader/products').then((response) => {
                    this.teamleader_products = response.data;
                    this.loading_teamleader_data = false;
                }).catch(() => {
                    this.loading_teamleader_data = false;
                })
            },
            getTeamleaderBookkeepingAccounts() {
                this.loading_teamleader_data = true;
                return this.$http.get('teamleader/bookkeeping-accounts').then((response) => {
                    this.teamleader_bookkeeping_accounts = response.data;
                    this.loading_teamleader_data = false;
                }).catch(() => {
                    this.loading_teamleader_data = false;
                })
            },
            authenticate() {
                return this.$http.post('teamleader/auth').then((response) => {
                    window.location = response.data.url;
                });
            },
            deauthenticate() {
                return this.$http.delete('teamleader/auth').then(() => {
                    this.getApiConfig('teamleader');
                });
            },
        }
    }
</script>
