<template>
    <div class="row">
        <div class="col">
            <b-card header="Instellingen">
                <form @submit.prevent="handle">
                    <div class="row">
                        <div class="col-xl-4">
                            <b-form-group label="Kies je facturatiekoppeling">
                                <select class="form-control" v-model="form.invoicing_api" required>
                                    <option :value="undefined" disabled>[ Kies een facturatiekoppeling ]</option>
                                    <option value="exact">Exact</option>
                                    <option value="teamleader">Teamleader</option>
                                </select>
                            </b-form-group>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xl-4">
                            <b-form-group label="Tijdsduur afronden op (minuten)">
                                <select class="form-control" v-model="form.invoicing_round_time_spent_minutes">
                                    <option :value="null">[ Geen afronding ]</option>
                                    <option :value="5">5 minuten</option>
                                    <option :value="10">10 minuten</option>
                                    <option :value="15">15 minuten</option>
                                    <option :value="30">30 minuten</option>
                                    <option :value="60">60 minuten</option>
                                </select>
                            </b-form-group>
                        </div>
                        <div class="col-xl-4" v-if="form.invoicing_round_time_spent_minutes">
                            <b-form-group label="Tijdsduur afronding">
                                <select class="form-control" v-model="form.invoicing_round_time_spent_type">
                                    <option value="default">Normaal</option>
                                    <option value="up">Omhoog</option>
                                    <option value="down">Omlaag</option>
                                </select>
                            </b-form-group>
                        </div>
                        <div class="col-xl-4" v-if="form.invoicing_round_time_spent_minutes">
                            <b-form-group label="Tijdsduur minimum" description="Tijdregistraties boven de 0 minuten zullen altijd minimaal deze tijdsduur krijgen.">
                                <b-form-input class="form-control" type="number" v-model="form.invoicing_round_time_spent_minimum">
                                </b-form-input>
                            </b-form-group>
                        </div>
                    </div>
                    <loading-button :loading="loading" type="submit" class="btn btn-sm btn-primary">Opslaan</loading-button>
                </form>
            </b-card>
            <b-card no-body>
                <b-tabs pills card>
                    <b-tab>
                        <template slot="title"><i class="fa fa-link" v-if="topdeskAuthenticated" title="Gekoppeld"></i> Topdesk</template>
                        <b-card-text><settings-topdesk-component @isAuthenticated="topdeskAuthenticated = $event"></settings-topdesk-component></b-card-text>
                    </b-tab>
                    <b-tab v-if="form.invoicing_api === 'exact'">
                        <template slot="title"><i class="fa fa-link" v-if="exactAuthenticated" title="Gekoppeld"></i> Exact</template>
                        <b-card-text><settings-exact-component @isAuthenticated="exactAuthenticated = $event"></settings-exact-component></b-card-text>
                    </b-tab>
                    <b-tab v-if="form.invoicing_api === 'teamleader'">
                        <template slot="title"><i class="fa fa-link" v-if="teamleaderAuthenticated" title="Gekoppeld"></i> Teamleader</template>
                        <b-card-text><settings-teamleader-component @isAuthenticated="teamleaderAuthenticated = $event"></settings-teamleader-component></b-card-text>
                    </b-tab>
                </b-tabs>
            </b-card>
        </div>
    </div>
</template>

<script>
    import SettingsExactComponent from '@/views/settings/Exact';
    import SettingsTopdeskComponent from '@/views/settings/Topdesk';
    import SettingsTeamleaderComponent from '@/views/settings/Teamleader';

    export default {
        components: {SettingsExactComponent, SettingsTopdeskComponent, SettingsTeamleaderComponent},
        mounted() {
            this.getConfig();
        },
        data() {
            return {
                exactAuthenticated: false,
                topdeskAuthenticated: false,
                teamleaderAuthenticated: false,
                form: {
                    invoicing_api: undefined,
                    invoicing_round_time_spent_minutes: null,
                    invoicing_round_time_spent_type: 'default',
                    invoicing_round_time_spent_minimum: null,
                },
                loading: false
            }
        },
        methods: {
            handle() {
                return this.saveConfig();
            },
            getConfig() {
                return this.$http.get('config').then((response) => {
                    for(let config of response.data) {
                        this.form[config.name] = config.value;
                    }
                })
            },
            saveConfig() {
                this.loading = true;
                return this.$http.post('config', this.form).then(() => {
                    this.loading = false;
                    this.$toastr.s('Instellingen opgeslagen!');
                }).catch(() => {
                    this.loading = false;
                });
            }
        }
    }
</script>
