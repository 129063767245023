<template>
    <div>
        <form @submit.prevent="updateApiConfig('topdesk')">
            <h4>API instellingen</h4>
            <b-form-group label="API endpoint">
                <b-form-input v-model="form.api_endpoint" required></b-form-input>
                <small>Zonder slash (/) op het einde. Bijvoorbeeld: https://innovaat.topdesk.net</small>
            </b-form-group>
            <b-form-group label="API username">
                <b-form-input v-model="form.api_username" required></b-form-input>
                <small>
                    Zorg dat de gebruiker voldoende rechten heeft. Dat zijn in ieder geval de volgende:
                    <ul>
                        <li>Meldingenbeheer -> Eerstelijns meldingen: Lezen / Schrijven / Archiveren</li>
                        <li>Meldingenbeheer -> Tweedelijns meldingen: Lezen / Schrijven / Archiveren</li>
                        <li>Ondersteunende bestanden -> Personen: Lezen / Schrijven / Aanmaken</li>
                        <li>Ondersteunende bestanden -> Klanten: Lezen / Schrijven / Aanmaken</li>
                        <li>Ondersteunende bestanden -> Vestigingen: Lezen / Schrijven / Aanmaken</li>
                        <li>Ondersteunende bestanden -> Behandelaarsgroepen: Lezen</li>
                        <li>API voor rapporteren -> REST-API: Lezen</li>
                        <li>API voor rapporteren -> Applicatiewachtwoorden gebruiken: Schrijven</li>
                    </ul>
                </small>
            </b-form-group>
            <b-form-group label="API password">
                <b-input-group>
                    <b-form-input :type="api_password_show ? 'text' : 'password'" v-model="form.api_password"
                                  ref="api_password" required></b-form-input>
                    <b-input-group-text slot="append" @click="api_password_show = !api_password_show"
                                        style="cursor: pointer"><i class="fa fa-eye"></i></b-input-group-text>
                </b-input-group>
                <small>
                    <a href="https://developers.topdesk.com/tutorial.html#show-collapse-usage-createAppPassword"
                       target="_blank">https://developers.topdesk.com/tutorial.html#show-collapse-usage-createAppPassword</a>
                </small>
            </b-form-group>
            <hr>
            <h4>Facturatie instellingen</h4>
            <!--            Select operatour group-->
            <b-form-group>
                <small>Alleen tickets van behandelaarsgroep laten zien:</small>
                <b-form-select :options="operatorGroups" v-model="form.operator_group">
                    <template slot="first">
                        <option value="">[ Alle behandelaarsgroepen ]</option>
                    </template>
                </b-form-select>
            </b-form-group>

            <!--            Only show tickets with status: after invoicing-->
            <b-form-group>
                <small>Alleen tickets weergeven met status:</small>
                <b-form-select :options="statuses" v-model="form.filter_status">
                    <template slot="first">
                        <option value="">[ Alle gesloten ]</option>
                    </template>
                </b-form-select>
            </b-form-group>

            <!--            Change incident status after invoicing-->
            <b-form-group>
                <small>Na het factureren de status van het incident aanpassen naar:</small>
                <b-form-select :options="statuses" v-model="form.change_status">
                    <template slot="first">
                        <option value="">[ Niet aanpassen ]</option>
                    </template>
                </b-form-select>
            </b-form-group>

            <!--            Archive after invoicing-->
            <b-form-group>
                <b-checkbox v-model="form.archive_on_close">Incident archiveren na het factureren</b-checkbox>
            </b-form-group>

            <!--            Enable synchronisation to TopDesk-->
            <!--            TODO: Move settings for synchronisation.-->
            <b-form-group>
                <b-checkbox v-model="form.exact_topdesk_sync">Debiteuren van Exact naar Topdesk synchroniseren.
                </b-checkbox>
            </b-form-group>
            <b-form-group>
                <b-button v-b-modal.modal-overview>Overzicht</b-button>
            </b-form-group>

            <p>Laatste succesvolle synchronisatie: <b>{{api_config.config.last_sync}}</b></p>

            <!--Create new branches when debtorcode can't be matched-->
            <b-form-group>
                <b-checkbox v-model="form.create_new_branches_on_sync">Nieuwe vestiging aanmaken als er geen debiteur
                    gematched kan worden.
                </b-checkbox>
            </b-form-group>

            <b-form-group>
                <b-checkbox v-model="form.created_only">
                    Alleen nieuwe items synchroniseren (bewerkte items worden genegeerd).
                </b-checkbox>
            </b-form-group>

            <b-form-group>
                <loading-button :loading="loading_form" type="submit" class="btn btn-sm btn-primary">Opslaan
                </loading-button>
            </b-form-group>
        </form>
        <b-modal size="xl" title="Overzicht" centered id="modal-overview" @show="onShowModal">
            <b-spinner variant="primary" v-if="loading"></b-spinner>
            <table class="table table-sm">
                <thead>
                <h3>Te synchroniseren:</h3>
                <tr v-if="matched.length > 0">
                    <th>Naam</th>
                    <th>E-mail</th>
                    <th>Telefoon</th>
                    <th>Website</th>
                    <th>Debiteurnummer</th>
                    <th>Straat</th>
                    <th>Huisnummer</th>
                    <th>Plaats</th>
                    <th>Postcode</th>
                    <th>Hoofdvestiging</th>
                    <th>optionalFields1.boolean1</th>
                    <th>optionalFields1.text1</th>
                    <th>optionalFields1.text2</th>
                    <th>optionalFields1.text3</th>
                    <th>optionalFields1.text4</th>
                    <th>optionalFields1.searchList1</th>
                </tr>
                </thead>
                <tbody>
                <h3 v-if="matched.length > 0">Matched</h3>
                <tr v-for="debtor in matched">
                    <td>{{debtor.updated_branch.name}}</td>
                    <td>{{debtor.updated_branch.email}}</td>
                    <td>{{debtor.updated_branch.phone}}</td>
                    <td>{{debtor.updated_branch.website}}</td>
                    <td>{{debtor.updated_branch.clientReferenceNumber}}</td>
                    <td>{{debtor.updated_branch.address.street}}</td>
                    <td>{{debtor.updated_branch.address.number}}</td>
                    <td>{{debtor.updated_branch.address.city}}</td>
                    <td>{{debtor.updated_branch.address.postcode}}</td>
                    <td>{{debtor.updated_branch.headBranch}}</td>
                    <td>{{debtor.updated_branch.optionalFields1.boolean1}}</td>
                    <td>{{debtor.updated_branch.optionalFields1.text1}}</td>
                    <td>{{debtor.updated_branch.optionalFields1.text2}}</td>
                    <td>{{debtor.updated_branch.optionalFields1.text3}}</td>
                    <td>{{debtor.updated_branch.optionalFields1.text4}}</td>
                    <td>{{debtor.updated_branch.optionalFields1.searchList1}}</td>
                </tr>
                <h3 v-if="unMatched.length > 0">Unmatched</h3>
                <tr v-for="debtor in unMatched">
                    <td>{{ debtor.Name }}</td>
                    <td>{{ debtor.Code }}</td>
                    <td>{{ debtor.Phone }}</td>
                    <td>{{ debtor.Email }}</td>
                    <td>{{ debtor.Website }}</td>
                    <td>{{ debtor.AddressLine1 }}</td>
                    <td>{{ debtor.City }}</td>
                    <td>{{ debtor.Postcode }}</td>
                    <td>{{ debtor.Country }}</td>
                </tr>
                <h3 v-if="suggested.length > 0">Suggested</h3>
                <tr v-for="debtor in suggested">
                    <td>{{ debtor.exact.Name }}</td>
                    <td>{{ debtor.exact.Code }}</td>
                    <td>{{ debtor.exact.Phone }}</td>
                    <td>{{ debtor.exact.Email }}</td>
                    <td>{{ debtor.exact.Website }}</td>
                    <td>{{ debtor.exact.AddressLine1 }}</td>
                    <td>{{ debtor.exact.City }}</td>
                    <td>{{ debtor.exact.Postcode }}</td>
                    <td>{{ debtor.exact.Country }}</td>
                </tr>
                </tbody>
            </table>
        </b-modal>
    </div>
</template>

<script>
    import mix from './mixin';

    export default {
        mounted() {
            this.getApiConfig('topdesk');
            this.loadOperatorGroups();
            this.loadStatuses();
        },
        mixins: [mix],
        data() {
            return {
                form: {
                    api_endpoint: undefined,
                    api_username: undefined,
                    api_password: undefined,
                    operator_group: undefined,
                    filter_status: undefined,
                    archive_on_close: undefined,
                    change_status: undefined,
                    exact_topdesk_sync: false,
                    create_new_branches_on_sync: false,
                    created_only: false,
                },
                matched: [],
                unMatched: [],
                suggested: [],
                operatorGroups: [],
                statuses: [],
                api_password_show: false,
                loading: false,
            }
        },
        computed: {
            isAuthenticated() {
                if (this.api_config && this.api_config.session) {
                    return this.api_config.session.token;
                }
            }
        },
        methods: {
            loadOperatorGroups() {
                this.$http.get('topdesk/api/operators/groups').then((response) => {
                    this.operatorGroups = response.data.map((item) => {
                        item.value = item.id;
                        item.text = item.groupName;
                        return item;
                    });
                });
            },
            loadStatuses() {
                return this.$http.get('topdesk/api/statuses').then((response) => {
                    this.statuses = response.data.map((item) => {
                        item.value = item.id;
                        item.text = item.name;
                        return item;
                    })
                });
            },
            loadOverview() {
                this.loading = true;
                return this.$http.get('debtors/list').then((response) => {
                    this.matched = response.data.already_matched;
                    this.suggested = response.data.suggested_matches;
                    this.unMatched = response.data.unmatched_exact;
                    this.loading = false;
                })
            },
            onShowModal() {
                this.loadOverview();
            },
        },
        watch: {
            isAuthenticated(val) {
                this.$emit('isAuthenticated', val);
            },
        }
    }
</script>
