<template>
    <div>
        <b-form-group v-if="canAuthenticate">
            <div class="btn-group" v-if="isAuthenticated">
                <button class="btn btn-primary" disabled><i class="fa fa-link"></i> Gekoppeld</button>
                <button @click="deauthenticate()" class="btn btn-outline-primary"><i class="fa fa-unlink"></i> Ontkoppelen</button>
            </div>
            <div class="btn-group" v-else>
                <button @click="authenticate()" class="btn btn-outline-primary"><i class="fa fa-unlink"></i> Koppelen</button>
                <button class="btn btn-primary" disabled><i class="fa fa-link"></i> Ontkoppeld</button>
            </div>
            <div v-if="!isAuthenticated">
                <small>Klik op Koppelen om te factureren naar Exact Online.</small>
            </div>
        </b-form-group>
        <div class="card border-primary">
            <div class="card-header bg-primary border-primary" style="cursor: pointer" @click="expanded_settings = !expanded_settings"><i class="fa fa-cog"></i> Exact API instellingen</div>
            <div class="card-body" v-if="expanded_settings">
                <div>
                    Volg deze stappen om Exact Online te koppelen:
                    <ol>
                        <li>Ga naar het <a href="https://apps.exactonline.com/" target="_blank">App Center</a>.</li>
                        <li>Klik op <b>Registeer API sleutels</b>.</li>
                        <li>Klik op <b>Registeer een nieuwe API sleutel</b>.</li>
                        <li>Vul een unieke naam in.</li>
                        <li>Gebruik als redirect URI: <b>https://app.invoicedesk.io/exact/webhook</b></li>
                        <li>Klik op <b>Opslaan</b>.</li>
                        <li>Vul het client ID, client secret en webhook secret wat je daarna krijgt te zien hieronder in.</li>
                        <li>Klik op Opslaan en koppel daarna je Exact Online administratie.</li>
                    </ol>
                </div>
                <form @submit.prevent="handleExactApiConfig">
                    <div class="row">
                        <div class="col-4">
                            <b-form-group label="Client ID">
                                <b-form-input v-model="form.client_id" required></b-form-input>
                            </b-form-group>
                        </div>
                        <div class="col-4">
                            <b-form-group label="Client Secret">
                                <b-input-group>
                                    <b-form-input :type="client_secret_show ? 'text' : 'password'" v-model="form.client_secret" ref="client_secret" required></b-form-input>
                                    <b-input-group-text slot="append" @click="client_secret_show = !client_secret_show" style="cursor: pointer"><i class="fa fa-eye"></i></b-input-group-text>
                                </b-input-group>
                            </b-form-group>
                        </div>
                        <div class="col-4">
                            <b-form-group label="Webhook Secret">
                                <b-input-group>
                                    <b-form-input :type="webhook_secret_show ? 'text' : 'password'" v-model="form.webhook_secret" required></b-form-input>
                                    <b-input-group-text slot="append" @click="webhook_secret_show = !webhook_secret_show" style="cursor: pointer"><i class="fa fa-eye"></i></b-input-group-text>
                                </b-input-group>
                            </b-form-group>
                        </div>
                    </div>
                    <button class="btn btn-sm btn-primary" type="submit">Opslaan</button>
                </form>
            </div>
        </div>
        <div class="card border-primary" v-if="isAuthenticated">
            <div class="card-header bg-primary border-primary"><i class="fa fa-cog"></i> Koppelingsinstellingen <a class="text-light pull-right" title="show dev" @click="show_webhooks = !show_webhooks" type="button"><i class="fa fa-eye"></i></a></div>
            <div class="card-body">
                <form @submit.prevent="handleExtraConfig">
                    <b-form-group label="Standaard administratie">
                        <b-input-group>
                            <b-input-group-text v-if="loading_exact_data" slot="prepend" class="text-primary"><i class="fa fa-circle-o-notch fa-spin"></i>&nbsp;Administratie laden...</b-input-group-text>
                            <b-form-select v-model="form.default_division">
                                <option :value="undefined">[ Gebruik de standaard administratie ]</option>
                                <option v-for="item in exact_divisions" :value="item['Code']">
                                    [{{ item['Code'] }}] {{ item['Description'] }}
                                </option>
                            </b-form-select>
                        </b-input-group>
                        <small>(optioneel) Kies de standaard administratie. Klik na het wijzigen van de administratie op Opslaan. Controleer daarna onderstaande opties.</small>
                    </b-form-group>
                    <hr />
                    <b-form-group label="Standaard product">
                        <b-form-select v-model="form.default_product" required>
                            <option :value="undefined">[ Kies een optie ]</option>
                            <option v-for="item in exact_items" :value="item['ID']">
                                [{{ item['Code'] }}] {{ item['Description'] }}
                            </option>
                        </b-form-select>
                        <small>Kies het product waarmee gefactureerd mag worden. Zorg dat dit product de eenheid 'Uur' heeft en de eigenschap 'Deelbaar' aanstaat.</small>
                    </b-form-group>
                    <b-form-group label="Standaard grootboekrekening">
                        <b-form-select v-model="form.default_gl_account" required>
                            <option :value="undefined">[ Kies een optie ]</option>
                            <option v-for="item in exact_gl_accounts" :value="item['ID']">
                                {{ item['Description'] }} [{{ item['Code'] }}]
                            </option>
                        </b-form-select>
                        <small>Op welke grootboekrekening mag je omzet geboekt worden van de facturen.</small>
                    </b-form-group>
                    <b-form-group label="Standaard dagboek">
                        <b-form-select v-model="form.default_journal" required>
                            <option :value="undefined">[ Kies een optie ]</option>
                            <option v-for="item in exact_journals" :value="item['Code']">
                                {{ item['Description'] }} [{{ item['Code'] }}]
                            </option>
                        </b-form-select>
                    </b-form-group>
                    <b-form-group label="Standaard tarief">
                        <b-input-group prepend="€">
                            <b-form-input v-model="form.default_rate" @keypress.native="onlyNumber"></b-form-input>
                        </b-input-group>
                        <small>(optioneel) Dit tarief wordt standaard ingevuld bij facturatie. Dit is aanpasbaar per klant in TOPdesk bij de vrije velden, maar kan ook per factuur aangepast worden.</small>
                    </b-form-group>
                    <b-form-group>
                        <b-form-checkbox v-model="form.on_create_send_invoice">
                            Factuur direct versturen na aanmaken
                        </b-form-checkbox>
                        <small>De factuur wordt als concept klaar gezet, of direct verstuurd.</small>
                    </b-form-group>

                    <loading-button type="submit" :loading="loading_form" class="btn btn-sm btn-primary">Opslaan</loading-button>&nbsp;

                    <div class="card mt-2 mb-0" v-if="show_webhooks">
                        <small class="p-1">Geregistreerde webhooks</small>
                        <table class="table table-sm" style="font-size: 80%">
                            <tr>
                                <th>Topic</th>
                                <th>URL</th>
                                <th>Beschrijving</th>
                                <th>Gemaakt door</th>
                            </tr>
                            <tr v-for="webhook in exact_webhooks">
                                <td>{{ webhook.Topic }}</td>
                                <td>{{ webhook.CallbackURL }}</td>
                                <td>{{ webhook.Description }}</td>
                                <td>{{ webhook.CreatorFullName }}</td>
                            </tr>
                        </table>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import mix from './mixin';
    export default {
        mounted() {
            this.getApiConfig('exact')
                .then((response) => {
                    if(this.isAuthenticated) {
                        this.loadExactData();
                    } else {
                        this.expanded_settings = true;
                    }
                });
        },
        mixins: [mix],
        data() {
            return {
                form: {
                    client_id: undefined,
                    client_secret: undefined,
                    webhook_secret: undefined,
                    default_division: undefined,
                    default_product: undefined,
                    default_gl_account: undefined,
                    default_journal: undefined,
                    default_rate: undefined,
                    on_create_send_invoice: undefined,
                    on_create_debtor_sync_topdesk: undefined,

                },
                expanded_settings: false,
                webhook_secret_show: false,
                client_secret_show: false,
                exact_items: [],
                exact_divisions: [],
                exact_gl_accounts: [],
                exact_journals: [],
                exact_webhooks: [],
                show_webhooks: false,
                loading_exact_data: false
            }
        },
        computed: {
            isAuthenticated() {
                return !!this.api_config.session;
            },
            canAuthenticate() {
                if(this.api_config && this.api_config.config) {
                    return this.api_config.config.client_id && this.api_config.config.client_secret;
                }
                return false;
            }
        },
        watch: {
            isAuthenticated(val) {
                this.$emit('isAuthenticated', val);
            }
        },
        methods: {
            handleExactApiConfig() {
                this.updateApiConfig('exact').then(() => {
                    this.$toastr.s('Exact App instellingen opgeslagen!');
                }).then(() => {
                    return this.getApiConfig('exact');
                });
            },
            handleExtraConfig() {
                this.updateApiConfig('exact').then(this.loadExactData);
                if(this.form.on_create_debtor_sync_topdesk) {
                    this.createExactWebhook('Accounts');
                } else {
                    this.deleteExactWebhook('Accounts');
                }
            },
            getExactDivisions() {
                return this.$http.get('exact/api/divisions').then((response) => {
                    this.exact_divisions = response.data;
                });
            },
            getExactItems() {
                return this.$http.get('exact/api/items').then((response) => {
                    this.exact_items = response.data;
                });
            },
            getExactGlaccounts() {
                return this.$http.get('exact/api/gl-accounts').then((response) => {
                    this.exact_gl_accounts = response.data;
                });
            },
            getExactJournals() {
                return this.$http.get('exact/api/journals').then((response) => {
                    this.exact_journals = response.data;
                });
            },
            getExactWebhooks() {
                return this.$http.get('exact/api/webhooks').then((response) => {
                   this.exact_webhooks = response.data;
                });
            },
            createExactWebhook(topic) {
                return this.$http.post('exact/api/webhooks', {
                    topic: topic
                }).then(this.getExactWebhooks);
            },
            deleteExactWebhook(topic) {
                return this.$http.post('exact/api/webhooks/delete', {
                    topic: topic
                }).then(this.getExactWebhooks);
            },
            authenticate() {
                return this.$http.post('exact/auth').then((response) => {
                    window.location = response.data.url;
                });
            },
            deauthenticate() {
                return this.$http.delete('exact/auth').then(() => {
                    this.getApiConfig('exact');
                });
            },
            loadExactData() {
                this.loading_exact_data = true;
                return this.getExactDivisions()
                    .then(this.getExactItems)
                    .then(this.getExactWebhooks)
                    .then(this.getExactGlaccounts)
                    .then(this.getExactJournals)
                .then(() => {
                    this.loading_exact_data = false;
                }).catch(() => {
                    this.loading_exact_data = false;
                })
            }
        }
    }
</script>
