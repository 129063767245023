export default {
    data() {
        return {
            api_config: {
                config: {}
            },
            form: {},
            loading_form: false
        }
    },
    methods: {
        getApiConfig(name) {
            return this.$http.get(`api-config/${name}`).then((response) => {
                this.api_config = response.data;
                this.form = {...this.form, ...response.data.config};
                return response;
            });
        },
        updateApiConfig(name) {
            this.loading_form = true;
            return this.$http.put(`api-config/${name}`, this.form).then(() => {
                this.loading_form = false;
            }).catch(() => {
                this.loading_form = false;
            });
        }
    }
}
